// Importing necessary types and functions from external modules
import { type SyncDefinition } from 'o365.pwa.modules.client.SyncDefinition.ts';
import { SyncProgress, type ISyncProgressJSON } from 'o365.pwa.modules.client.SyncProgress.ts';
import { type StepDefinition, type IStepTruncateIndexedDB, isIOfflineStepDefinition, isIOnlineStepDefinition, isIStepTruncateIndexedDB } from 'o365.pwa.modules.client.steps.StepDefinition.ts';
import { SyncStatus, type StepSyncProgress } from 'o365.pwa.modules.client.steps.StepSyncProgress.ts';
import { UIFriendlyMessage } from 'o365.pwa.modules.UIFriendlyMessage.ts';
import { localStorageHelper } from 'o365-modules';
import { setQueryParameter } from 'o365.modules.utils.url.ts';
import { app as App } from 'o365-modules';
import IndexedDBHandler from 'o365.pwa.modules.client.IndexedDBHandler.ts';
import { TruncateIndexDBObjectStoreMode } from 'o365.pwa.types.ts';
import type { SyncDefinitionId } from 'o365.pwa.types.ts';


// Declaring global variables to store the current sync definition and other data
let currentlySyncing: boolean = false;
const memory: Map<string, any> = new Map<string, any>();
const dependencyMapping: Map<string, number> = new Map<string, number>();

/**
 * Initiates a synchronization process based on the provided sync definition.
 *
 * @param {string} syncDefinitionId - The identifier of the synchronization definition.
 * @param {SyncDefinition} syncRunDefinition - The synchronization definition object containing the steps and other sync configurations.
 * @param {boolean} [continueSync=false] - A flag indicating whether to continue a previously interrupted sync or to start a new sync.
 *
 * @returns {Promise<SyncProgress>} - A promise that resolves with the sync progress object that contains details about the sync status.
 *
 * @throws Will throw an error if the sync process encounters an issue at any stage.
 * @throws Will throw an error if a valid step type is not found.
 * @throws Will throw an error if the sync process is interrupted and the current progress cannot be retrieved.
 */
export default async function startSync(syncDefinitionId: SyncDefinitionId, syncRunDefinition: SyncDefinition, getPwaVueAppInstance: Function, continueSync: boolean = false): Promise<SyncProgress> {
    let syncProgress = new SyncProgress();

    try {
        await checkCurrentState();

        syncRunDefinition.currentSyncProgress = new SyncProgress();
        currentlySyncing = true;

        syncProgress = syncRunDefinition.currentSyncProgress;

        memory.clear();
        dependencyMapping.clear();

        await checkAndHandleContinueSync({
            continueSync: continueSync,
            syncDefinition: syncRunDefinition,
            syncDefinitionId: syncDefinitionId,
            syncProgress: syncProgress
        });

        initializeStepProgress({
            syncDefinition: syncRunDefinition,
            syncProgress: syncProgress
        });

        for (let stepIndex = syncProgress.currentStepIndex; stepIndex < syncRunDefinition.steps.length; stepIndex++) {
            syncProgress.currentStepIndex = stepIndex;

            const stepDefinition = syncRunDefinition.steps[stepIndex];
            const stepProgress = syncProgress.resourcesProgress[stepIndex];

            if (checkIfSyncIsCancelled({
                syncProgress: syncProgress,
                stepProgress: stepProgress
            })) {
                continue;
            }

            if (runStepDependencyChecks({
                syncDefinition: syncRunDefinition,
                syncProgress: syncProgress,
                stepDefinition: stepDefinition,
                stepProgress: stepProgress
            })) {
                continue;
            }

            await checkIfPageReloadIsRequired({
                syncProgress: syncProgress,
                syncDefinitionId: syncDefinitionId
            });

            await checkForTruncateMode({
                truncateModeAllowed: 'TRUNCATE_BEFORE_OFFLINE_SYNC',
                stepDefinition: stepDefinition,
                syncProgress: syncProgress,
                stepProgress: stepProgress,
                stepIndex: stepIndex,
                dependencyMapping: dependencyMapping,
                getPwaVueAppInstance: getPwaVueAppInstance,
                syncRunDefinition: syncRunDefinition
            });

            stepProgress.syncStatus = SyncStatus.Syncing;

            if (syncRunDefinition.syncType === 'OFFLINE-SYNC' && isIOfflineStepDefinition(stepDefinition)) {
                await stepDefinition.syncOffline({
                    syncProgress: syncProgress,
                    stepProgress: stepProgress,
                    memory: memory,
                    syncRunDefinition: syncRunDefinition,
                    currentIndex: stepIndex,
                    dependencyMapping: dependencyMapping,
                    getPwaVueAppInstance: getPwaVueAppInstance
                });
            } else if (syncRunDefinition.syncType === 'ONLINE-SYNC' && isIOnlineStepDefinition(stepDefinition)) {
                await stepDefinition.syncOnline({
                    syncProgress: syncProgress,
                    stepProgress: stepProgress,
                    memory: memory,
                    currentIndex: stepIndex,
                    dependencyMapping: dependencyMapping,
                    syncRunDefinition: syncRunDefinition,
                    getPwaVueAppInstance: getPwaVueAppInstance
                });
            } else {
                throw Error('Failed to find a valid step type');
            }

            await checkForTruncateMode({
                truncateModeAllowed: 'TRUNCATE_AFTER_ONLINE_STEP_SYNC',
                stepDefinition: stepDefinition,
                syncProgress: syncProgress,
                stepProgress: stepProgress,
                stepIndex: stepIndex,
                dependencyMapping: dependencyMapping,
                getPwaVueAppInstance: getPwaVueAppInstance,
                syncRunDefinition: syncRunDefinition
            });

            setStatusBasedOnStatus({ stepProgress });
        }

        if (syncProgress.hasError === false) {
            for (let stepIndex = 0; stepIndex < syncRunDefinition.steps.length; stepIndex++) {
                const stepDefinition = syncRunDefinition.steps[stepIndex];
                const stepProgress = syncProgress.resourcesProgress[stepIndex];

                await checkForTruncateMode({
                    truncateModeAllowed: 'TRUNCATE_AFTER_ONLINE_SYNC',
                    stepDefinition: stepDefinition,
                    syncProgress: syncProgress,
                    stepProgress: stepProgress,
                    stepIndex: stepIndex,
                    dependencyMapping: dependencyMapping,
                    getPwaVueAppInstance: getPwaVueAppInstance,
                    syncRunDefinition: syncRunDefinition
                });

                setStatusBasedOnStatus({ stepProgress });
            }
        }

        const someStepsCanceled = syncProgress.resourcesProgress.some((stepProgress: StepSyncProgress) => stepProgress.syncStatus === SyncStatus.SyncingCanceled);

        if (someStepsCanceled === false) {
            syncProgress.markedAsCanceled = false;
        }

        await new Promise((resolve) => setTimeout(resolve, 1000));
    } catch (reason) {
        console.error(reason);
        
        let error: Error;

        if (reason instanceof Error) {
            error = reason;
        } else if (typeof reason === 'string') {
            error = new Error(reason);
        } else if (reason) {
            error = new Error(JSON.stringify(reason));
        } else {
            error = new Error('Something has gone wrong');
        }

        syncProgress.errors.push(error);
    } finally {

        syncProgress.dateTimeEnd = new Date();

        currentlySyncing = false;
    }

    console.debug(`Completed sync ${syncDefinitionId}`, syncDefinitionId, syncProgress);

    return syncProgress;
}

/**
 * Checks the current state of the application and sync process before initiating a new synchronization.
 *
 * This function performs the following checks:
 * 1. Ensures that the application data is accessible in the IndexedDB.
 * 2. Ensures that the Progressive Web App (PWA) state is available.
 * 3. Checks if the service worker is successfully installed.
 * 4. Verifies that no other sync process is currently in progress.
 *
 * @returns {Promise<void>} - A promise indicating the completion of the state check process.
 *
 * @throws Will throw an error if the app data is not found in the IndexedDB.
 * @throws Will throw an error if the PWA state is not found.
 * @throws Will throw an error if the service worker is not successfully installed.
 * @throws Will throw an error if another sync process is already in progress.
 */
async function checkCurrentState(): Promise<void> {
    const appId = App.id;

    const app = await IndexedDBHandler.getApp(appId);

    if (app === null) {
        throw new Error('Unable to start sync. Could not find app');
    }

    const pwaState = await app.pwaState;

    if (pwaState === null) {
        throw new Error('Unable to start sync. Could not find PWA State');
    }

    const serviceWorkerState = await app.serviceWorkerState;

    if (serviceWorkerState === null) {
        throw new Error('Unable to start sync. Could not find Service Worker State');
    }

    if (!serviceWorkerState.installed) {
        throw new Error('Unable to start sync. Could not find service worker');
    }

    if (currentlySyncing) {
        throw new Error('Failed to start sync. A sync is already in progress');
    }
}

/**
 * Handles continuation of sync if the continueSync flag is true.
 * @param {object} options - The options object containing necessary parameters.
 * @param {boolean} options.continueSync - Indicates whether to continue a previous sync.
 * @param {string} options.syncDefinitionId - The ID of the sync definition.
 * @param {SyncProgress} options.syncProgress - The current sync progress object.
 * @param {SyncDefinition} options.syncDefinition - The sync definition object.
 */
async function checkAndHandleContinueSync(options: {
    continueSync: boolean;
    syncDefinitionId: SyncDefinitionId;
    syncProgress: SyncProgress;
    syncDefinition: SyncDefinition;
}): Promise<void> {
    const {
        continueSync,
        syncDefinitionId,
        syncProgress,
        syncDefinition
    } = options;


    if (!continueSync) {
        return;
    }

    const storedSyncProgressString = localStorageHelper.getItem(`PWA-Sync-${syncDefinitionId}`, { 'global': false });

    if (storedSyncProgressString === null) {
        throw new Error('Failed to continue sync. Could not find current progress');
    }

    const storedSyncProgress: ISyncProgressJSON = JSON.parse(storedSyncProgressString);

    localStorageHelper.removeItem(`PWA-Sync-${syncDefinitionId}`, { 'global': false });

    syncProgress.dateTimeStart = new Date(storedSyncProgress.dateTimeStart);
    syncProgress.currentStepIndex = storedSyncProgress.currentStepIndex;

    for (const [stepIndex, storedSyncStepProgress] of storedSyncProgress.resourcesProgress.entries()) {
        if (syncProgress.currentStepIndex <= stepIndex) {
            break;
        }

        const stepDefinition = syncDefinition.steps[stepIndex];

        const stepProgress = stepDefinition.generateStepProgress(storedSyncStepProgress);

        dependencyMapping.set(stepDefinition.stepId, stepIndex);

        syncProgress.resourcesProgress[stepIndex] = stepProgress;
    }
}

/**
 * Generates step progress objects for each step in the sync definition.
 * @param {object} options - The options object containing necessary parameters.
 * @param {SyncDefinition} options.syncDefinition - The sync definition object.
 * @param {SyncProgress} options.syncProgress - The current sync progress object.
 */
function initializeStepProgress(options: {
    syncDefinition: SyncDefinition,
    syncProgress: SyncProgress
}): void {
    const { syncDefinition, syncProgress } = options;

    for (const [stepIndex, stepDefinition] of syncDefinition.steps.entries()) {
        if (stepIndex < syncProgress.resourcesProgress.length) {
            continue;
        }

        const stepProgress = stepDefinition.generateStepProgress(undefined, syncDefinition.syncType);

        dependencyMapping.set(stepDefinition.stepId, stepIndex);

        syncProgress.resourcesProgress[stepIndex] = stepProgress;
    }
}

function runStepDependencyChecks(options: {
    syncProgress: SyncProgress;
    syncDefinition: SyncDefinition;
    stepDefinition: StepDefinition;
    stepProgress: StepSyncProgress
}): boolean {
    const { syncProgress, syncDefinition, stepDefinition, stepProgress } = options;

    const checkDependency = (dependencyId: string) => {
        const dependencyIndex = dependencyMapping.get(dependencyId);

        if (dependencyIndex === undefined) {
            throw new Error('Dependency check failed. Could not find dependency');
        }

        const dependencyStepProgress = syncProgress.resourcesProgress[dependencyIndex];
        const dependencyStepDefinition = syncDefinition.steps[dependencyIndex]

        if (dependencyStepProgress.hasError || dependencyStepDefinition.dependOnPreviousStep.some((subDependencyId) => checkDependency(subDependencyId))) {
            return true;
        }

        return false;
    }

    const stepsWithFailedDependencyCheck: Set<string> = new Set<string>(stepDefinition.dependOnPreviousStep.filter((
        dependencyId: string,
        _index: number,
        _dependencies: Array<string>
    ) => checkDependency(dependencyId)));

    if (stepsWithFailedDependencyCheck.size === 0) {
        return false;
    }

    stepProgress.syncStatus = SyncStatus.SyncingCompleteWithErrors;
    stepProgress.uiFriendlyMessages.push(new UIFriendlyMessage(
        'ERROR',
        'Failed dependency check',
        `Following steps required to complete successfully before this step can run: ${Array.from(stepsWithFailedDependencyCheck).join(', ')}`
    ));

    return true;
}

function checkIfSyncIsCancelled(options: { syncProgress: SyncProgress, stepProgress: StepSyncProgress }): boolean {
    const { syncProgress, stepProgress } = options;

    if (syncProgress.markedAsCanceled) {
        stepProgress.syncStatus = SyncStatus.SyncingCanceled;
    }

    return syncProgress.markedAsCanceled;
}

async function checkIfPageReloadIsRequired(options: { syncProgress: SyncProgress, syncDefinitionId: SyncDefinitionId }): Promise<void> {
    const { syncProgress, syncDefinitionId } = options;

    if (!syncProgress.requirePageReload) {
        return;
    }

    localStorageHelper.setItem(`PWA-Sync-${syncDefinitionId}`, JSON.stringify(syncProgress), { 'global': false });

    setQueryParameter('pwa-continue-sync', syncDefinitionId, true);

    await new Promise(() => { });
}

async function checkForTruncateMode(options: {
    truncateModeAllowed: TruncateIndexDBObjectStoreMode;
    stepDefinition: StepDefinition;
    syncProgress: SyncProgress;
    stepProgress: StepSyncProgress;
    stepIndex: number;
    dependencyMapping: Map<string, number>;
    getPwaVueAppInstance: Function;
    syncRunDefinition: SyncDefinition;
}): Promise<boolean> {
    const { truncateModeAllowed, stepDefinition, syncProgress, stepProgress, stepIndex, dependencyMapping, getPwaVueAppInstance, syncRunDefinition } = options;

    if (!isIStepTruncateIndexedDB(stepDefinition) || truncateModeAllowed !== stepDefinition.truncateMode) {
        return false;
    }

    switch (stepDefinition.truncateMode) {
        case 'TRUNCATE_BEFORE_OFFLINE_SYNC':
            return await runOfflineSyncTruncateMode({
                syncProgress: syncProgress,
                stepDefinition: stepDefinition,
                stepProgress: stepProgress,
                currentIndex: stepIndex,
                dependencyMapping: dependencyMapping,
                getPwaVueAppInstance: getPwaVueAppInstance,
                syncRunDefinition: syncRunDefinition
            });
        case 'TRUNCATE_AFTER_ONLINE_STEP_SYNC':
            return await runOnlineSyncAfterStepTruncateMode({
                syncProgress: syncProgress,
                stepDefinition: stepDefinition,
                stepProgress: stepProgress,
                currentIndex: stepIndex,
                dependencyMapping: dependencyMapping,
                getPwaVueAppInstance: getPwaVueAppInstance,
                syncRunDefinition: syncRunDefinition
            });
        case 'TRUNCATE_AFTER_ONLINE_SYNC':
            // Set the current step index
            syncProgress.currentStepIndex = stepIndex;

            return await runOnlineSyncAfterSyncTruncateMode({
                syncProgress: syncProgress,
                stepDefinition: stepDefinition,
                stepProgress: stepProgress,
                currentIndex: stepIndex,
                dependencyMapping: dependencyMapping,
                getPwaVueAppInstance: getPwaVueAppInstance,
                syncRunDefinition: syncRunDefinition
            });
    }

    return false;
}

async function runOfflineSyncTruncateMode(options: {
    syncProgress: SyncProgress;
    stepDefinition: StepDefinition & IStepTruncateIndexedDB<StepSyncProgress>;
    stepProgress: StepSyncProgress;
    currentIndex: number;
    dependencyMapping: Map<string, number>;
    getPwaVueAppInstance: Function;
    syncRunDefinition: SyncDefinition;
}): Promise<boolean> {
    const { syncProgress, stepDefinition, stepProgress, currentIndex, dependencyMapping, getPwaVueAppInstance, syncRunDefinition } = options;

    stepProgress.syncStatus = SyncStatus.PreSyncCleanupStarted;

    await stepDefinition.truncateData({
        syncProgress: syncProgress,
        stepProgress: stepProgress,
        memory: memory,
        currentIndex: currentIndex,
        dependencyMapping: dependencyMapping,
        getPwaVueAppInstance: getPwaVueAppInstance,
        syncRunDefinition: syncRunDefinition,
    });

    if (stepProgress.syncStatus !== SyncStatus.PreSyncCleanupStarted) {
        return true;
    }

    return false;
}

async function runOnlineSyncAfterStepTruncateMode(options: {
    syncProgress: SyncProgress;
    stepDefinition: StepDefinition & IStepTruncateIndexedDB<StepSyncProgress>;
    stepProgress: StepSyncProgress;
    currentIndex: number;
    dependencyMapping: Map<string, number>;
    getPwaVueAppInstance: Function;
    syncRunDefinition: SyncDefinition;
}): Promise<boolean> {
    const { syncProgress, stepDefinition, stepProgress, currentIndex, dependencyMapping, getPwaVueAppInstance, syncRunDefinition } = options;

    stepProgress.syncStatus = SyncStatus.SyncingCompletedStartedCleaning;

    await stepDefinition.truncateData({
        syncProgress: syncProgress,
        stepProgress: stepProgress,
        memory: memory,
        currentIndex,
        dependencyMapping: dependencyMapping,
        getPwaVueAppInstance: getPwaVueAppInstance,
        syncRunDefinition: syncRunDefinition,
    });

    if (stepProgress.syncStatus === SyncStatus.SyncingCompletedStartedCleaning) {
        stepProgress.syncStatus = SyncStatus.SyncingComplete;
    }

    return false
}

async function runOnlineSyncAfterSyncTruncateMode(options: {
    syncProgress: SyncProgress;
    stepDefinition: StepDefinition & IStepTruncateIndexedDB<StepSyncProgress>;
    stepProgress: StepSyncProgress;
    currentIndex: number;
    dependencyMapping: Map<string, number>;
    getPwaVueAppInstance: Function;
    syncRunDefinition: SyncDefinition;
}): Promise<boolean> {
    const { syncProgress, stepDefinition, stepProgress, currentIndex, dependencyMapping, getPwaVueAppInstance, syncRunDefinition } = options;

    switch (stepProgress.syncStatus) {
        case SyncStatus.Syncing:
            stepProgress.syncStatus = SyncStatus.SyncingCompletedAwaitingCleanup;
            break;
        case SyncStatus.SyncingCompletedAwaitingCleanup:
            stepProgress.syncStatus = SyncStatus.SyncingCompletedStartedCleaning;

            await stepDefinition.truncateData({
                syncProgress: syncProgress,
                stepProgress: stepProgress,
                memory: memory,
                currentIndex: currentIndex,
                dependencyMapping: dependencyMapping,
                getPwaVueAppInstance: getPwaVueAppInstance,
                syncRunDefinition: syncRunDefinition,
            });

            if (stepProgress.syncStatus === SyncStatus.SyncingCompletedStartedCleaning) {
                stepProgress.syncStatus = SyncStatus.SyncingComplete;
            }
            break;
    }

    return false;
}

function setStatusBasedOnStatus(options: { stepProgress: StepSyncProgress }) {
    const { stepProgress } = options;

    switch (stepProgress.syncStatus as SyncStatus) {
        case SyncStatus.Syncing:
            stepProgress.syncStatus = SyncStatus.SyncingComplete;
            break;
        case SyncStatus.SyncingWithWarnings:
            stepProgress.syncStatus = SyncStatus.SyncingCompleteWithWarnings;
            break;
        case SyncStatus.SyncingWithErrors:
            stepProgress.syncStatus = SyncStatus.SyncingCompleteWithErrors;
            break;
    }
}
